import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';

export default function Chart(props) {
	
	const divId = props.divId;
	const chart = useRef(null);
	
	useEffect(() => {
		chart.current = props.createFunc(divId);
		return () => {
			chart.current.dispose();
		}
	}, []);
	
	useEffect(() => {
		
		let total = 0;
		props.data.forEach(d => total += +d.votes);
		const newData = props.data.map(d => {
			const row = {...d};
			row.votesPercent = total > 0 ? Math.round(d.votes / total * 100) : 0;
			row.styleSettings = {fill: am5.color(row.seriesColor)};
			return row;
		});
		
		chart.current.series.values[0].data.setAll(newData);
		
		props.onUpdate && props.onUpdate(chart, newData);
		
	}, [props.data]);
	
	return <div id={divId} className={'amcharts5-wrap'}></div>
}

Chart.propTypes = {
	divId: PropTypes.string,
	createFunc: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired
}

Chart.defaultTypes = {
	divId: 'chartdiv'
}