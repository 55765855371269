import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';
import VerticalBarsChart from './charts/VerticalBarsChart';
import SimplePieChart from './charts/SimplePieChart';
import Counter from '../components/Counter';

export default function PollScreen(props) {
	
	const model = props.poll;
	const counter = model.counter || {};
	
	return <Screen
		className={`poll ${model.cssClass ? model.cssClass : ''}`}
		bgColor={model.bgColor}
		bgImg={model.background}
	>
		{(model && model.customCss) && <style>{model.customCss}</style>}
		<div className={`root h${model.chartPosition}`}>
			<div className={`chart`}>
				{(!model.empty && model.chartType === 'bars') && <VerticalBarsChart data={model.options} divId={props.divId}/>}
				{(!model.empty && model.chartType === 'pie') && <SimplePieChart data={model.options} divId={props.divId}/>}
			</div>
			{(model.counter && model.counter.display) && <Counter className={`counter v${counter.vPosition} h${counter.hPosition}`} value={model.total} {...counter}/>}
		</div>
	</Screen>
}

PollScreen.propTypes = {
	divId: PropTypes.string,
	poll: PropTypes.object.isRequired
};

PollScreen.defaultProps = {
	divId: 'chartdiv'
}