import React from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import Chart from './Chart';

export default function SimplePieChart(props) {
	
	const create = divId => {
		
		const root = am5.Root.new(divId);
		root.setThemes([
			am5themes_Animated.new(root)
		]);
		
		const chart = root.container.children.push(
			am5percent.PieChart.new(root, {
				radius: am5.percent(94)
			})
		);
		
		const series = chart.series.push(
			am5percent.PieSeries.new(root, {
				valueField: 'votes',
				categoryField: 'text',
				alignLabels: false
			})
		);
		
		series.labels.template.setAll({
			radius: 100,
			inside: true,
			fontSize: 25,
			text: "[{textColor}][bold]{category}:[/] [{textColor}]{valuePercentTotal.formatNumber('0.')}%[/]"
		});
		
		series.slices.template.setAll({
			strokeOpacity: 0,
			templateField: 'styleSettings'
		});
		
		series.ticks.template.set('forceHidden', true);
		
		series.data.setAll(props.data);
		
		return chart;
	}
	
	return <Chart createFunc={create} data={props.data} divId={props.divId}/>
}

SimplePieChart.propTypes = {
	data: PropTypes.array.isRequired,
	divId: PropTypes.string
};