import React from 'react';
import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function Counter(props) {
	return (
		<div className={`${props.className} counter`} css={css`background-color: ${props.bgColor};
			border-color: ${props.borderColor};
			border-style: solid;
			border-width: ${props.borderSize}px;
			color: ${props.color};
			font-size: ${props.fontSize}px;
			margin: ${props.margin}px;
			padding: ${props.padding}px;`}>
			{props.value} {props.value === 1 ? props.singular : props.plural}
		</div>
	);
}

Counter.propTypes = {
	fontSize: PropTypes.number,
	bgColor: PropTypes.string,
	borderColor: PropTypes.string,
	borderSize: PropTypes.number,
	className: PropTypes.string,
	color: PropTypes.string,
	margin: PropTypes.number,
	padding: PropTypes.number,
	plural: PropTypes.string,
	singular: PropTypes.string,
	value: PropTypes.number
}

Counter.defaultProps = {
	fontSize: 50,
	bgColor: '#000',
	borderColor: '#000',
	borderSize: 0,
	className: '',
	color: '#fff',
	margin: 5,
	padding: 5,
	plural: 'votes',
	singular: 'vote',
	value: 0
}