import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import WatermarkContext from '../context/WatermarkContext';

export default function WaterMark(props) {
	const position = props.position;
	const showWatermark = useContext(WatermarkContext);
	
	return (
		<>
			{showWatermark && <div className={`watermark ${position}`}>
				<img src={'https://streamsmart.io/assets/img/watermark.png'} alt={'StreamSmart.io Watermark'}/>
			</div>
			}
		</>
	);
}

WaterMark.propTypes = {
	position: PropTypes.string
};

WaterMark.defaultProps = {
	position: 'mid-right'
}